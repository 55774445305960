export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // //Auto Login 
  // {
  //   path: '/apps/home',
  //   name: 'apps-home',
  //   component: () => import('@/views/apps/landing-page/Home.vue'),
  // },

  //Landing Page
  {
    path: '/apps/home',
    name: 'apps-home',
    component: () => import('@/views/apps/landing-page/Home.vue'),
    // meta: {
    //   layout: 'full',
    //   resource: 'Auth',
    //   redirectIfLoggedIn: true,
    // },
  },

  //Master Hak Akses
  {
    path: '/apps/hak-akses/list',
    name: 'apps-hak-akses-list',
    component: () => import('@/views/apps/master-hak-akses/master-hak-akses-list/HakaksesList.vue'),
  },
  {
    path: '/apps/hak-akses/add',
    name: 'apps-hak-akses-add',
    component: () => import('@/views/apps/master-hak-akses/master-hak-akses-add/HakaksesAdd.vue'),
  },
  {
    // path: '/apps/hak-akses/edit',
    path: '/apps/hak-akses/edit/:hakakses_name',
    name: 'apps-hak-akses-edit',
    component: () => import('@/views/apps/master-hak-akses/master-hak-akses-edit/HakaksesEdit.vue'),
  },


  //Master Divisi
  {
    path: '/apps/divisi/list',
    name: 'apps-divisi-list',
    component: () => import('@/views/apps/master-divisi/master-divisi-list/DivisiList.vue'),
  },
  {
    path: '/apps/divisi/add',
    name: 'apps-divisi-add',
    component: () => import('@/views/apps/master-divisi/master-divisi-add/DivisiAdd.vue'),
  },
  {
    // path: '/apps/divisi/edit',
    path: '/apps/divisi/edit/:div_name',///:div_bu
    name: 'apps-divisi-edit',
    component: () => import('@/views/apps/master-divisi/master-divisi-edit/DivisiEdit.vue'),
  },

  //Master Mapping Divisi
  {
    path: '/apps/mapping-divisi/list',
    name: 'apps-mapping-divisi-list',
    component: () => import('@/views/apps/master-mapping-divisi/master-mapping-divisi-list/MappingDivisiList.vue'),
  },
  {
    path: '/apps/mapping-divisi/add',
    name: 'apps-mapping-divisi-add',
    component: () => import('@/views/apps/master-mapping-divisi/master-mapping-divisi-add/MappingDivisiAdd.vue'),
  },
  {
    path: '/apps/mapping-divisi/edit/:div_name/:div_bu',
    name: 'apps-mapping-divisi-edit',
    component: () => import('@/views/apps/master-mapping-divisi/master-mapping-divisi-edit/MappingDivisiEdit.vue'),
  },

  //Master Mapping User
  {
    path: '/apps/mapping-user/list',
    name: 'apps-mapping-user-list',
    component: () => import('@/views/apps/master-mapping-user/master-mapping-user-list/MappinguserList.vue'),
  },
  {
    path: '/apps/mapping-user/add',
    name: 'apps-mapping-user-add',
    component: () => import('@/views/apps/master-mapping-user/master-mapping-user-add/MappinguserAdd.vue'),
  },
  {
    // path: '/apps/mapping-user/edit',
    path: '/apps/mapping-user/edit/:map_user_pk',
    name: 'apps-mapping-user-edit',
    component: () => import('@/views/apps/master-mapping-user/master-mapping-user-edit/MappinguserEdit.vue'),
  },
  {
    path: '/apps/mapping-user/detail/:map_user_pk',
    name: 'apps-mapping-user-detail',
    component: () => import('@/views/apps/master-mapping-user/master-mapping-user-detail/MappinguserDetail.vue'),
  },

  //Master Approval Sales
  {
    path: '/apps/approval-sales/list',
    name: 'apps-approval-sales-list',
    component: () => import('@/views/apps/master-approval/master-approval-list/ApprovallList.vue'),
  },
  {
    path: '/apps/approval-sales/add',
    name: 'apps-approval-sales-add',
    component: () => import('@/views/apps/master-approval/master-approval-add/ApprovallAdd.vue'),
  },
  {
    // path: '/apps/approval-sales/edit',
    path: '/apps/approval-sales/edit/:app_map_user_pk/:app_div_bu/:app_div_name/:app_segmen_code/:app_level',
    name: 'apps-approval-sales-edit',
    component: () => import('@/views/apps/master-approval/master-approval-edit/ApprovallEdit.vue'),
  },


  //Master Kategori Produk
  {
    path: '/apps/kategori-produk/list',
    name: 'apps-kategori-produk-list',
    component: () => import('@/views/apps/master-kategori/master-kategori-list/KategoriList.vue'),
  },
  {
    path: '/apps/kategori-produk/add',
    name: 'apps-kategori-produk-add',
    component: () => import('@/views/apps/master-kategori/master-kategori-add/KategoriAdd.vue'),
  },
  {
    path: '/apps/kategori-produk/edit/:cat_kode/:cat_bu/:cat_prodline',
    name: 'apps-kategori-produk-edit',
    component: () => import('@/views/apps/master-kategori/master-kategori-edit/KategoriEdit.vue'),
  },

  //Master Email Mengetahui
  {
    path: '/apps/email-mengetahui/list',
    name: 'apps-email-mengetahui-list',
    component: () => import('@/views/apps/master-email/master-email-list/EmailList.vue'),
  },
  {
    path: '/apps/email-mengetahui/add',
    name: 'apps-email-mengetahui-add',
    component: () => import('@/views/apps/master-email/master-email-add/EmailAdd.vue'),
  },
  {
    // path: '/apps/email-mengetahui/edit',
    path: '/apps/email-mengetahui/edit/:cc_bu/:cc_email',
    name: 'apps-email-mengetahui-edit',
    component: () => import('@/views/apps/master-email/master-email-edit/EmailEdit.vue'),
  },

  //List Pengajuan Sales
  {
    path: '/apps/pengajuan-sales/list',
    name: 'apps-pengajuan-sales-list',
    component: () => import('@/views/apps/pengajuan-sales/pengajuan-sales-list/SalesList.vue'),
  },
  {
    path: '/apps/pengajuan-sales/preview/:trn_code',
    name: 'apps-pengajuan-sales-preview',
    component: () => import('@/views/apps/pengajuan-sales/pengajuan-sales-preview/SalesPreview.vue'),
  },
  {
    path: '/apps/pengajuan-sales/add',
    name: 'apps-pengajuan-sales-add',
    component: () => import('@/views/apps/pengajuan-sales/pengajuan-sales-add/SalesAdd.vue'),
  },
  // {
  //   path: '/apps/pengajuan-sales/edit/:trn_code',
  //   name: 'apps-pengajuan-sales-edit',
  //   component: () => import('@/views/apps/pengajuan-sales/pengajuan-sales-add/SalesAdd.vue'),
  // },


  //List Approval Sales
  {
    path: '/apps/approval/list',
    name: 'apps-approval-list',
    component: () => import('@/views/apps/approval-sales/approval-sales-list/ApprovalList.vue'),
  },
  {
    path: '/apps/approval/edit/:trn_code',
    name: 'apps-approval-edit',
    component: () => import('@/views/apps/approval-sales/approval-sales-edit/ApprovalEdit.vue'),
  },


  //Monitoring Pengajuan
  {
    path: '/apps/monitoring-pengajuan/list',
    name: 'apps-monitoring-pengajuan-list',
    component: () => import('@/views/apps/monitoring-pengajuan/monitoring-pengajuan-list/MonitoringList.vue'),
  },

  {
    path: '/apps/monitoring-pengajuan/detail/:trn_code',
    name: 'apps-monitoring-pengajuan-detail',
    component: () => import('@/views/apps/monitoring-pengajuan/monitoring-pengajuan-edit/MonitoringEdit.vue'),
  },

  // {
  //   path: '/apps/monitoring-pengajuan/pdf/:trn_code',
  //   name: 'apps-monitoring-pengajuan-pdf',
  //   component: () => import('@/views/apps/monitoring-pengajuan/monitoring-pengajuan-pdf/MonitoringPDF.vue'),
  // },
]
