import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import VueNumberFormat from 'vue-number-format'
import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueNumberFormat, {prefix: '', decimal: '.', thousand: ','})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  data() {
    return {
      timeoutId: null,
    }
  },
  created() {
    this.startTimeout()
  },
  mounted() {
    document.addEventListener('mousemove', e => {
      // this.lastClick = `x: ${e.clientX} | y: ${e.clientY}`
      // console.log('Mouse');
      this.cancelTimeout()
    })
  },
  methods: {
    startTimeout() {
      this.timeoutId = setTimeout(() => {
        this.logoutSys()
        // console.log('Logout')
      }, 180000)
    },
    cancelTimeout() {
      clearTimeout(this.timeoutId)
      this.startTimeout()
    },
    async logoutSys() {
      const params = new URLSearchParams()
      params.append('emp_id', localStorage.getItem('emp_id'))
      params.append('param', localStorage.getItem('userData'))
      params.append('url', window.location.origin)
      params.append('keterangan', 'system')
      params.append('status', 'logout')
      await axios.post(`${useAppConfig().AppUrl}global/insertLogAkses`, params)
      // window.localStorage.clear()

      localStorage.clear()
      // window.location.replace(`${useAppConfig().PortalUrl}`)
      // window.location = `${useAppConfig().PortalUrl}`
      window.close();
    },
  },
  render: h => h(App),
}).$mount('#app')
